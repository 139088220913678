.website-content {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      margin: 5px 0;
      line-height: 28px;
      list-style-type: none;
      padding-left: 25px;
      position: relative;
      &:after {
        content: "\F105";
        font-family: 'FontAwesome';
        position: absolute;
        color: #006862;
        left: 0;
        top: 0;
        font-size: 18px;
      }
    }
    &.list-mark {
      li:after {
        content: "\F00C";
      }
    }
  }

}
