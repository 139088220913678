.header.vertical-menu {
  background: white;
  border-right: 1px solid black;
}


.vertical-menu .menu .mega-menu .menu-links > li {
  padding: 0;
  transition: none;
  > a {
    text-align: center;
    color: black;
    text-transform: none;
    padding: 10px 23px;
    &:hover {
      color: $color-main;
      font-weight: bold;
    }
  }
  &:hover, &:focus {
    cursor: pointer;
    a {
      color: $color-main;
      font-weight: bold;
    }
  }
}
.mega-menu.vertical-left .menu-logo > li > a, .mega-menu.vertical-right .menu-logo > li > a {
  text-align: center;
}
.vertical-menu .menu {
  .menu-logo {
    padding: 55px 30px 40px;
    img {
      height: auto;
      width: 100%;
      float: none;
      display: inline-block;
    }
  }
  .menu-links > li > a {
    line-height: 20px;
    &:hover, &:focus {
      text-decoration: none !important;
    }
  }
  .menu-links {
    margin-top: 120px;
  }
}
.vertical-menu .menu .mega-menu .menu-links {
  background: transparent;
}

@media (max-width: 991px) {
  .vertical-menu {
    .menu {
      .menu-logo {
        padding: 35px 30px 20px;
      }
      .menu-links {
        margin-top: 0;
      }
      .mega-menu .menu-links > li {
        border-color: rgba(255, 255, 255, 0.2);
        text-align: center;
      }
      .mega-menu .menu-links > li > a {
        padding: 5px 13px;
        color: black;
      }
      .mega-menu .menu-links > li {
        padding: 5px 0;
        transition: none;
      }
    }
  }
  .menu-responsive {
    padding: 5px 20px;
    height: 70px;
    background: white;
    img {
      height: 60px;
    }
    i.fa-bars {
      color: black;
    }
  }
}

