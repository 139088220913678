body {
  font-family: 'Montserrat', sans-serif;
  color: #151515;
  p {
    color: #151515;
  }
}

h1 {
  font-family: 'Oswald', sans-serif;
  font-weight: 300 !important;
}
h2, h3, h4, h5, h6 {
  font-family: 'Oswald', sans-serif;
  font-weight: 400 !important;
}

h1 {
  font-size: 26px;
}
h2 {
  font-size: 20px;
}
h2 {
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}
h3 {
  font-size: 16px;
}
h4 {
  font-size: 16px;
}
a, button, input {
  outline: medium none !important;
  color: $color-main;
  transition: none;
  &:hover, &:focus {
    color: $color-main;
    text-decoration: underline !important;
  }
}

.page-content {
  h1 {
    font-size: 30px;
  }
  .section-title {
    margin-bottom: 10px;
  }
  .section-title p {
    font-size: 14px;
    font-weight: 400;
  }
}
img {
  max-width: 100%;
}
.who-we-are-left .owl-carousel .owl-dots {
  bottom: -35px;
}