.page-background-image {
  height: 300px;
}
#header-image {
  .slider-content {
    margin-top: -35px;
    .headline-text {
      font-size: 40px;
      line-height: 50px;
      font-family: 'Lato', sans-serif;
    }
    .content-text {
      font-size: 20px;
      color: white;
      line-height: 20px;
    }
  }
}
@media (max-width: 991px) {
  #header-image {
    .slider-content {
      margin-top: -20px;
      .headline-text {
        font-size: 30px;
        line-height: 40px;
      }
      .content-text {
        font-size: 16px;
        color: white;
      }
    }
  }
}