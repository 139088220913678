.startpage-box {
  h2 {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;

  }
  .startpage-content-box {
    padding-left: 60px;
  }
  a {
    font-size: 13px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  p.startpage-oneliner {
    padding-top: 3px;
  }
}
.startpage-tab {
  .tab-pane {
    max-width: 600px;
  }
}
.section-startpage.section-title {
  margin-bottom: 20px;
}
@media (max-width: 991px) {
  .startpage-box .startpage-content-box {
    padding-left: 0;
    margin-bottom: 25px;
    p {
      margin-bottom: 0.5rem;
    }
  }
  #img-startpage {
    margin-bottom: 25px;
  }
}
