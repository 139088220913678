$color-main: rgb(0,104,98);
$color-green-dark: #2d4129;
$color-blue: #0F599B;
$color-blue-2: #6287be;
$color-blue-3: #7e98bd;

@import "base/base";
@import "base/menu";
@import "base/header";
@import "base/form";
@import "base/content";
@import "base/list";
@import "base/footer";

@import "special/startpage";
@import "special/contactpage";