select.form-control:not([size]):not([multiple]) {
  height: auto;
}

.button.icon-color {
  background: none;
  border: none;
  color: $color-main;
  padding: 0;
}

.btn-primary {
  background-color: $color-main;
  border-color:$color-main;
}

.btn-primary.focus, .btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 104, 98, 0.5);
  background: $color-main;
  border-color: $color-main;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background: $color-main;
  border-color: $color-main;
}

.button.button-border.white:hover, .button.button-border.white:focus {
  background: #fff;
  color: $color-main;
  border-color: #fff;
}
a.button:hover, .button:focus, ul.nav a.nav-link, .btn:hover {
  text-decoration: none !important;
}