.tab .nav.nav-tabs > li > a.active, .nav.nav-tabs > li > a.active:focus, .nav.nav-tabs > li > a.active:hover {
  background: #323232;
  color: #fff;
  border-color: transparent;
}

.tab .nav.nav-tabs li a {
  padding: 12px 15px;
}

.section-title:nth-child(odd) .title-effect::before {
  background: transparent;
}
::selection {
  background: $color-main;
  color: #fff;
  text-shadow: none;
}

.green-bg {
  background: $color-green-dark;
}
.blue-bg {
  background: $color-blue;
}
.theme-bg {
  background: $color-main;
}

.our-service {
  padding-bottom: 80px;
}

.owl-carousel .owl-dots .owl-dot:hover span, .owl-carousel .owl-dots .owl-dot.active span {
  background: $color-main;
}

.action-box.full-width a.button {
  min-width: 175px;
  i {
    padding-left: 5px;
  }
}
.action-box .action-box-text {
  h3 {
    line-height: 34px;
  }
}
@media only screen and (min-width: 768px) {
  .content-top {
    min-height: 310px;
    height: 310px;
  }
}
.content-seperator {
  height: 2px;
  background: $color-main;
  margin-top: 0;
}