.page-contact {
  h2 {
    font-size: 30px;
    font-weight: normal;
  }
  .contact-success {
    color: $color-main;
    font-weight: bold;
    margin-bottom: 15px;
  }
}